import React from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';
import ImgWarning from '../../../assets/img/under_construction.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Loader } from '../../../shared/Components';
import { usePermissionsStatus } from '../../../hooks/usePermissions';

const AccessDenied = (props) => {
  const history = useHistory();
  const status = usePermissionsStatus();

  return (
    <div className="align-items-center h-100 d-flex">
      {status === 'loading' ? (
        <Loader></Loader>
      ) : (
        <Container>
          <Row className="text-center ">
            <Col md="12" className="align-items-center">
              <div className="p-3">
                <Image src={ImgWarning} width="30%" />
              </div>
            </Col>
            <Col md="12">
              <h2 className="pt-3 mb-3">Access Denied</h2>
              <p className="text-muted">
                You don't have access to this feature. Please contact your admin.
              </p>
            </Col>
            <Col md="12">
              <Button
                onClick={() => (props?.handleClick ? props?.handleClick() : history.goBack())}
                variant={'primary'}>
                Go back
              </Button>
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default AccessDenied;
