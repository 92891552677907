const ApiEndpoints = {
  //Catalog Api
  BROWSE_NODES: '/catalog/customer/browse-nodes',
  IMPACT_METRICS: '/sustainability_item_metrics.json',
  NODE_ITEMS: '/catalog/epr/items',

  //Target Api
  TARGETS: '/epr/targets',
  // TARGETS: 'http://localhost:3000/TargetResponseFlat.json',
  TARGETS_UNALLOCATED: '/epr/targets/unallocated',
  STATES: '/epr/states', //wantsDistricts.equals=true

  //Auth Api
  LOGIN: '/stakeholders/customer/authenticate',
  GET_GSTIN_BY_PAN: '/stakeholders/pan-info/',
  REGISTER: '/stakeholders/epr/registration',
  RESET_PASS_REQUEST: '/stakeholders/account/reset_password/init',
  RESET_PASS: '/stakeholders/account/reset_password/finish',
  USER_VALIDATION: '/stakeholders/epr/validate-user',
  AUTHENTICATE_GOOGLE_TOKEN: '/stakeholders/authenticate-google-auth-token',

  //Account Api
  GET_ACCOUNT: '/stakeholders/account',
  DEVICE_DETAILS: '/communication/devices',
  MY_CLIENTS: '/stakeholders/epr/clients',
  UPDATE_CONTACT_PERSON_DETAILS: '/stakeholders/epr/customer/contact-person',
  UPDATE_PROFILE_INFO_DETAILS: '/stakeholders/epr/customer-profile',
  UPLOAD_PROFILE_LOGO: '/stakeholders/customer/profile-logo',
  UPDATE_PASSWORD: '/stakeholders/account/change-password',
  UPDATE_PROFILE: '/stakeholders/epr/profile-update',
  GET_ACTIVITY_LOG: '/communication/notifications',
  GET_TIMELINE_LOG: '/audit/audit-logs?isTimeLineEvent=true&sort=createdAt,desc',
  SET_LOG_READ: '/communication/notifications/tapped',
  GET_COMMUNICATION_CATEGORIES: '/communication/category',
  GET_ALL_PERMISSIONS_FOR_ROLE: '/stakeholders/service/authority',
  ADD_ROLE_PERMISSIONS: '/stakeholders/service/authority',

  //PrivacyPolicy apis
  GET_CUSTOMER_SESSION: '/stakeholders/admin/customers/get-session',
  GET_EPR_CUSTOMERS: '/stakeholders/admin/customers',
  GET_USER_POLICY: '/stakeholders/user-policy',
  GET_LATEST_POLICY: '/stakeholders/privacy-policy',
  UPDATE_USER_POLICY: '/stakeholders/user-policy',

  //Brand API
  BRAND: '/stakeholders/epr/brands',
  BRAND_STATS: '/epr/targets/brand/dashboard-stats',

  // Work orders
  WORK_ORDERS: '/epr/work-orders',
  WORK_ORDERS_FILTER_FACETS: '/epr/work-orders/facets',
  WORK_ORDERS_PENDING_ITEMS: '/epr/work-orders/pending-items',
  PENDING_WO: '/epr/work-orders/pending-wo',
  DELETE_WO_DOC: '/epr/documents/delete',
  WORK_ORDER_ITEMS: '/epr/work-order-items',
  WORK_ORDER_ITEM_PRICE_CHANGE: '/epr/work-order-items/pricechange',
  DOWNLOAD_WORKORDERS: '/epr/download/work-order',
  DOWNLOAD_DELIVERIES_WORKORDER: '/epr/download/work-order/deliveries',
  WORK_ORDERS_CLOSE: '/epr/work-orders/close-wo',
  WORK_ORDERS_OPEN: '/epr/work-orders/open-wo',
  WORK_ORDER_LOG: '/epr/work-order-status-logs',
  WORK_ORDERS_BY_BRAND: '/epr/work-orders/brand',

  //Execution
  EXECUTION_FILTER_FACETS: '/epr/targets/execution/facets',

  //summmary
  FETCH_SUMMARY_STATUS: '/epr/summary/status',

  //Dashboard
  DASHBOARD_MET_STATS: '/epr/met-stats',
  DASHBOARD_OVERVIEW: '/epr/dashboard-overview',
  DASHBOARD_OVERVIEW_BY_STATE: '/epr/dashboard-states',
  DASHBOARD_OVERVIEW_BY_ITEM: '/epr/dashboard-items',
  DASSHBOARD_OVERVIEW_BY_BRAND: '/epr/dashboard-brands',
  DASSHBOARD_FACETS: '/epr/dashboard/facets',

  //Reports
  GET_VENDOR_REPORTS: '/epr/reports?vendor=true',
  GET_TARGET_REPORTS: '/epr/reports?vendor=false',
  GET_MONTHLY_FULFILLMENT_SUMMARY: '/epr/reports/monthlyFulfillment',
  GET_CPCB_REPORT: '/epr/reports/cpcb',
  GET_FULFILLMENT_REPORT: '/epr/reports/fulfillmentSummary',
  GET_PDF_PAGE: '/epr/reports/pdf-page',
  GET_SELF_DECLARATION_CERTIFICATE: '/epr/reports/declarationCertificate',
  GET_SIGNATURES: '/epr/reports/declaration-signature ',
  SEND_FULFIMENT_COMMUNICATION: '/epr/work-orders/fulfilment-plan/communication',

  // file uploads
  EPR_SIGNED_URL: '/epr/documents/signed-url',
  STAKEHOLDERS_SIGNED_URL: '/stakeholders/documents/signed-url',
  BULK_UPLOAD: '/epr/delivery-automation/upload',
  DISCARD_BATCH: '/epr/delivery-automation/remove-file',
  BATCH_DETAILS_LIST: '/epr/batch-processing/get-batches',
  PROCESS_DOC: '/epr/delivery-automation/process-batch',

  // Get base64 file
  GET_CSV_BASE64: '/epr/document-extraction/download-transactions',

  //ServiceProvider API
  SERVICE_PROVIDER: '/stakeholders/epr/service-providers',
  FILTERED_SERVICE_PROVIDERS: '/stakeholders/epr/work-orders',
  CLIENT_DETAILS: '/stakeholders/epr/brand',
  FILTERED_CLIENT_SERVICE_PROVIDERS: '/stakeholders/epr/work-orders?onlyFromReceived=true&',
  MAP_RECYCLER_TO_SP: '/epr/service-provider',
  GET_SP_RECYCLERS: '/epr/service-provider/',
  GET_SP_BRIEF_OVERVIEW_BY_CATEGORY: '/epr/inventory/customer-inventory-spdetails',
  GET_VENDOR_UNIT_DETAILS: '/epr/inventory/customer-inventory',

  //Delivery API
  DELIVERIES: '/epr/deliveries',
  DELIVERIES_UPLOAD_DOC: '/epr/delivery-documents',
  DELIVERY_DOCS_ARCHIVE: '/epr/deliveries/archive-certificates',
  DELIVERIES_CHANGE_TYPE: '/epr/delivery-documents/doc-type',
  ASSIGN_DELIVERY: '/epr/deliveries/assign',
  ATTRIBUTION_MONTH_CHANGE: '/epr/deliveries/attribution-month-change',
  STATUS_CHANGE: '/epr/deliveries/delivery-status-change',
  APPROVE_DELIVERY: '/epr/deliveries/mark-as-approved',
  DISPUTE_DELIVERY: '/epr/deliveries/mark-as-dispute',
  DELIVERY_DOCUMENT: '/epr/delivery-documents/',
  DISPUTE_DOCUMENT: '/epr/delivery-documents/mark-as-disputed',
  APPROVE_DOCUMENT: '/epr/delivery-documents/mark-as-approved',
  RESOLVE_DOCUMENT: '/epr/delivery-documents/mark-as-resolved',
  DELIVERY_DOCUMENT_META_DATA: '/epr/delivery-documents/meta-data',
  DELIVERY_FACETS: '/epr/deliveries/facets',
  DOWNLOAD_DELIVERIES: '/epr/download/deliveries',
  APPROVE_ALLOCATION: '/epr/deliveries/approve-allocation',
  QTY_SUBMIT_FOR_APPROVAL: '/epr/deliveries/service-provider/submit',
  GENERATE_EWAYBILL_EINVOICE: '/epr/deliveries/generate-einvoice-ewb?',
  GENERATE_INVOICE: '/epr/deliveries/reserve-invoice-number?',
  VIEW_INVOICE: '/epr/deliveries/invoice-preview?',
  GET_ORDER_SUMMARY: '/epr/executionSummary/orders',
  GET_DELIVERY_SUMMARY: '/epr/executionSummary/deliveries',
  GET_EXECUTION_DELIVERIES: '/epr/deliveries',
  GET_ALL_DOC_TYPES: '/epr/deliveries/configurations',
  GET_RECYCLER_ADDRESSES: '/epr/deliveries/recycler-source-details',
  GET_SOURCE_ADDRESSES: '/epr/deliveries/source-details',
  GET_RECYCLER_FROM_MP: '/epr/recyclers?size=1000',
  STATUS_TO_INTRANSIT: '/epr/deliveries/mark-as-in-transit',
  SEND_PICKUP_SUPERVISOR_SMS: '/epr/deliveries/supervisor/pick/send-sms',
  SEND_SMSTO_DRIVER: '/tracking/subscribe',
  SEND_DROP_SUPERVISOR_SMS: '/epr/deliveries/supervisor/destination/send-sms',
  SUPERUSERDELIVERIES: '/epr/deliveries/superuser',
  BULKDELETE: '/epr/delivery-documents/bulk-delivery-documents',
  GENERATE_SALVAGE_INVOICE: '/epr/invoices/create-salvage-invoice?',
  UPDATE_SALVAGE_PRICE: '/epr/deliveries/update-salvage-price?',
  SEND_SUBSCRIPTION: '/epr/deliveries/send-subscription',
  BATCH_DETAILS: '/epr/batch-processing/batch-details',
  AUTOMATE_DELIVERY: '/epr/delivery-automation/automate',
  FETCH_WORKORDER_DETAILS: '/epr/batch-processing/work-order',
  GET_SERVICE_PROVIDERS: '/epr/service-provider',
  GET_RECYCLERS: '/epr/recyclers',
  GET_RECYCLERS_INFO: '/epr/recyclers-info',
  GET_DOC_EXTRACTION: '/epr/document-extraction/transaction',
  GET_DOC_PREVIEW: '/epr/documents/document-preview',
  UPDATE_TRANSACTION: '/epr/document-extraction/update-transaction',

  //Roles API
  GET_ROLES_LIST: 'https://my-json-server.typicode.com/sravanthimunaga/recykal-api/roeldata',
  GET_ALL_PERMISSION: '/stakeholders/epr-permissions',
  GET_ALL_ROLES: '/stakeholders/epr-roles',
  GET_ALL_ROLE_PERMISSION: '/stakeholders/epr-role-permissions',
  GET_ALL_ROLES_LIST: '/stakeholders/service/authority',
  GET_ALL_PERMISSIONS_FOR_APP_CODE: '/stakeholders/service/permission',

  //Tickets
  TICKETS: '/epr/tickets',
  GET_USER: '/stakeholders/epr/users?sort=id,asc',
  GET_USERV2: '/stakeholders/epr/v2/users?sort=id,asc',
  DELETE_USER: '/stakeholders/delete-epr-user/',
  ENABLE_USER: '/stakeholders/enable-epr-user/',
  GET_ZONES_LIST: '/stakeholders/epr/zones',

  GET_INVOICES: '/epr/invoices',
  ACCOUNT_INVOICES: '/epr/invoices/accounted',
  GET_INVOICEABLE_DELIVERIES: '/epr/deliveries/invoiceable',
  GET_PAYMENTS: '/epr/payments',

  DOCUMENT_ATTACH: '/epr/documents/attach',
  WEIGHBRIDGE_CHECK: '/epr/deliveries/weighbridge-check?docType=',
  SUPERVISORS: '/epr/supervisors',

  TRANSPORTER: '/epr/transporters',
  DRIVER: '/epr/drivers',
  VEHICLE_NUMBER: '/epr/transporters/vehicle-details',

  PICKUPLOCATION: '/stakeholders/customer-addresses',
  DROPLOCATION: '/epr/recyclers?size=1000',

  SUPERVISOR_SEND_OTP: '/stakeholders/login/supervisor/request-otp',
  VERIFY_OTP: '/stakeholders/login/supervisor/verify-otp',

  // Tracking endpoints
  TRACKING: '/api/tracking/shipment',
  UNSUBSCRIBE: '/api/tracking/unsubscribe',
  AUTHENTICATE: '/epr/tracking/authenticate',

  FETCH_ULB: '/epr/ulbs',
  APPROVE_ULB_DOC: '/epr/ulb-doc/',
  UPLOAD_UNSIGNED_DOC: '/epr/ulb/',
  UPLOAD_SIGNED_DOC: '/epr/ulb-doc/',
  ULB_LITE: '/epr/ulbs/lite',
  SP_FETCH_ULB: '/epr/ulb-docs',

  GET_SPCB_LETTER_DATA: '/epr/deliveries',
  DOWNLOAD_SPCB_LETTER: '/epr/reports/spcb-letter',
  ADD_SPCB_STATE: '/epr/spcbs',
  GET_SPCB_LIST: '/epr/spcbs',

  DOWNLOAD_COPROCESSRECYCLE_LETTER: '/epr/reports/co-processing-certificate',
  DOWNLOAD_RECYCLING_LETTER: '/epr/reports/recycler-certificate',
  GRAPH_DATA: '/epr/reports/monthlyFulfillment',

  EWASTE_BRANDS: '/epr/ewaste-brands',
  BRAND_DETAILS: '/epr/brand-specifications/',
  DELIVERY_DETAILS: '/epr/delivery-status-logs',

  SUPPLY_FROM_VENDORS: '/epr/inventory/customer-inventory-planning',

  GET_INVOICE_LIST: '/epr/invoices/draft-invoices',
  POST_INVOICE: '/epr/invoices',
  APPROVED_INVOICE_LIST: '/epr/invoices',
  APPROVED_INVOICE: '/epr/invoices/',
  ATTACH_INVOICE_DOCUMENT: '/epr/documents/attach',
  UPDATE_INVOICE_DOCUMENT: '/epr/documents/update-document',
  CREATE_PROMISE_INVOICE: '/epr/invoices/promises',
  GET_PROMISE_LIST: '/epr/invoices/promises',
  EDIT_PROMISED_INVOICE: '/epr/invoices/promises',
  DELETE_PROMISED_INVOICE: '/epr/invoices/promises/',
  FETCH_APPROVED_CARD_DETAILS: '/epr/invoices/approved-invoices-cards',
  DOWNLOAD_APPROVED_REPORT: '/epr/download/invoices',
  RECORD_PAYMENT: '/epr/payments',
  ASSIGN_INVOICE: '/epr/payments/invoice_payment',
  FETCH_PAID_INVOICES: '/epr/invoices',
  BRAND_APPROVE_INVOICE: '/epr/invoices/approved',
  BRAND_REJECT_INVOICE: '/epr/invoices/rejected',
  FETCH_PAYMENT_LIST: '/epr/payments',
  DELETE_DOCUMENT: '/epr/documents/delete/',
  EDIT_INVOICES: '/epr/invoices',
  DELETE_PWA_DELIVERY: '/epr/deliveries/assigned',
  DELIVERY_BULK_UPLOAD: '/epr/deliveries/bulk/upload-docs',
  GENERATED_INVOICES: '/epr/invoices/client/proforma-generated-invoices',

  DOWNLOAD_REPORT: '/epr/download/epr-report',
  DOWNLOAD_SUMMARY_REPORT: '/epr/download/epr-summary-report',

  BRAND_INVOICE_TYPE_SAVE: '/epr/invoice-specs',
  BRAND_INVOICE_TYPE_GET: '/epr/invoice-specs/',
  BRAND_INVOICE_TYPE_UPDATE: '/epr/invoice-specs',
  ADVANCE_PROFORMA_INVOICE: '/epr/invoices/advance/proforma-invoices/',
  CUSTOMER_KAM_UPDATE: '/stakeholders/epr/update-customer-kam',

  GENERATE_PROFORMA_INVOICE: '/epr/invoices/client/proforma-invoices/',
  GET_PROFORMA_INVOICE: '/epr/invoices/client/proforma-invoices/',
  CREATE_INVOICE: '/epr/invoices/client/invoices/create',
  PREVIEW_INVOICE: '/epr/invoices/client/invoices/proforma/',
  MAP_DELIVERY_TO_INVOICE: '/epr/invoices/client/proforma-invoices-mapping/',

  SUPPLY_WORK_AREA: '/epr/capacity-projections',
  DEMAND_WORK_AREA: '/epr/capacity/demands',
  CUSTOMER_CAPACITIES: '/epr/capacity/customer-capacities',
  BRAND_DOCUMENTS: '/epr/deliverable-docs',

  SUPPLY_BUSUINESS_TARGET: '/epr/fulfilment-capacities',
  DEMAND_BUSUINESS_TARGET: '/epr/business-targets',
  VENDOR_AVAILABILITY: '/epr/executionSummary/orders',

  CREATE_MARKETPLACE_WORK_ORDER: '/epr/work-orders/generate-mp-wo',
  FETCH_UNPLANNED_WORK_ORDERS: '/epr/work-orders',
  UNPLANNED_PLAN: '/epr/work-orders/',
  UPDATE_DRAFT_WORK_ORDER: '/epr/work-orders/resend-vendor-consent',
  DELETE_DRAFT_WORK_ORDER: '/epr/work-orders/',
  CREATE_DRAFT_WORK_ORDER: '/epr/vendor-work-orders',

  MARKETPLACE_CREDITS: '/epr/deliveries/mp/get-credits',
  GENERATE_MARKETPLACE_WORK_ORDER: '/epr/work-orders/generate-mp-wo',
  PLANING_MODULES_CARDS: '/epr/plan-overview-data',
  AGGREGATOR_PV_CREATION_REQUEST: '/epr/deliveries/aggregator-pv-creation-req',
  CREATE_AGGREGATOR_PV: '/epr/deliveries/create-aggregator-pv',
  FETCH_BRANDWISE_PLANNED_WORK_ORDER_BY_ID: '/epr/work-orders/',
  FREE_WO_QUANTITY: '/epr/work-orders/free-qty',
  FETCH_BRAND_ALLOCATION_DETAILS: '/epr/work-order-items',
  ALLOCATE_WO_QUANTITY: '/epr/work-orders/replan-qty',
  ACCEPT_REJECT_DRAFT_WORK_ORDER: '/epr/work-orders/acceptVendorConsent',
  VENDOR_FULFILLMENT_DETAILS: '/epr/fulfilment-details',
  SAVE_VENDOR_FULFILLMENT_DETAILS: '/epr/fulfilment-details',
  UPDATE_VENDOR_FULFILLMENT_DETAILS: '/epr/fulfilment-capacities-update',
  FETCH_DELIVERABLES_LIST: '/epr/deliveries/get-docs-list',
  FETCH_SELECTED_DELIVERABLES_LIST: '/epr/customer-deliverable-docs/',
  SAVE_VENDOR_DELIVERABLES: '/epr/save-deliverable-docs',
  FETCH_REVISION_LOG: '/epr/capacitylogs/customer/',
  SAVE_VENDOR_ADDENDUM: '/epr/capacitylogs/capacity-revision-logs',
  DELETE_SELECTED_DELIVERABLES: '/epr/delete-by-doctype',

  // Vendor API
  GET_VENDOR_LIST: '/stakeholders/epr/vendor',
  UPDATE_VENDOR: '/stakeholders/epr/vendor/details',
  ADD_RECYCLER: '/epr/sp/add/recycler',
  GET_VENDOR_CAPABILITIES: '/epr/get-capacities',
  UPDATE_VENDOR_CAPABILITIES: '/epr/update-fulfilment',
  UPDATE_AGREEMENT_DETAILS: '/stakeholders/epr/recycler/agreement',
  UPDATE_CTO_DETAILS: '/stakeholders/epr/recycler/cto/details',
  GET_VENDOR_BY_ID: '/stakeholders/epr/vendor',
  UPLOAD_AGGREMENT: '/stakeholders/epr/service-providers/agreement/v2',
  ADD_CAPACITY: '/epr/inventory/add-capacity',
  INVENTORY_DETAILS: '/epr/inventory/get-planning-details',
  DELETE_CAPACITY: '/epr/inventory/delete-capacity',
  SAVE_CAPACITY: '/epr/save-category-capacity',

  // Execution Module
  BRAND_FULFILLMENT_TRACKING: '/epr/work-orders-execution',
  BRAND_CATEGORYWISE_FULFILLMENT_DATA: '/epr/work-order-items/fulfillment',
  VENDOR_FULFILLMENT_TRACKING: '/epr/service/vendor-fullfillment',
  VENDOR_WORK_ORDERS: '/epr/work-order-items/fulfillment/vendorworkorders',
  VENDOR_DETAILS: '/stakeholders/epr/service-providers',
  VENDOR_CATEGORYWISE_FULFILLMENT_DATA: '/epr/work-order-items/fulfillment/vendor',
  FETCH_VENDOR_WORKORDER_OVERVIEW_DETAILS:
    '/epr/work-order-items/fulfillment/overview/vendordetails',
  FETCH_DELIVERY_EXECUTION_DETAILS: '/epr/deliveries/execution/delivery-overview',
  UPDATE_BRAND_DELIVERY_STATUS: '/epr/deliveries/update-delivery-status',
  UPDATE_SP_LOGIN_DELIVERY_STATUS: '/epr/deliveries/service-provider/update-delivery-status',
  AUTO_ALLOCATION: '/epr/deliveries/assign/autoallocation',
  ADD_CERTIFICATE_DATE: '/epr/delivery-documents/certificate-date',
  PAYABLES_DOWNLOAD: '/epr/invoices/download-payables',
  DELINK_INVOICE: '/epr/invoices/client',
  UPDATE_BULK_DELIVERY_STATUS: '/epr/deliveries/update-bulk-delivery-status',

  GET_HSN_SAC_CODES: '/epr/deliveries/hsn-sac-codes',

  //Credits
  FETCH_WORKORDERS: '/epr/work-order-items/fetch-work-orders',
  ASSIGN_CREDIT_INFO: '/epr/assign-credit-info',
  ADD_CREDIT_INFO: '/epr/credit-info',
  GET_CREDIT_INFORMATION: '/epr/credit-informations',
  VIEW_CREDIT_INFORMATION: '/epr/credit-information-view',
  GET_VISIBILE_RECYCLERS: '/epr/visible-recyclers',
  GET_TRANSACTIONS_LIST: '/epr/credit-transactions',

  FETCH_VENDOR_WORK_ORDERS: '/epr/mapped-wo',
  FETCH_CERTIFICATE_DETAILS: '/epr/certificate',
  DELETE_CETIFICATE: '/epr/certificate/delete',
  FETCH_CERTIFICATES: '/epr/certificate/fetch-certificates',
  UPLOAD_CERTIFICATE: '/epr/certificate/document',
  UPDATE_CERTIFICATE: '/epr/certificate/update',

  FULFILLMENT_CATEGORY_WISE: '/epr/fulfillment-catagory-wise',

  //Onboard Vendor
  GET_MARKETPLACE_GSTIN_BY_PAN: '/marketplace/gstin/',
  VALIDATE_VENDOR: '/stakeholders/customer-details-pan-gst',
  ADD_VENDOR_DETAILS: '/stakeholders/epr/vendor/details',
  UPDATE_UNIT_ADDRESS: '/stakeholders/update-unit-address',
  ADD_FULFILLMENT_DETAILS: '/epr/add-fulfilment-details',
};

export default ApiEndpoints;
